<script>
import MenuClientes from "components/clientes/components/cliente/include/MenuClientes"

export default {
  name: 'ClientesPage',
  provide: function () {
    return {
      page: this,
      container: this
    }
  },
  mixins: [],
  components: {
    MenuClientes
  },
  data() {
    return {
      loading: true,
      routeName: 'clientes.lista',
      queues: [],
      fixedQueues: [],
      /*menuActive: {
        name: 'opened',
        label: 'Em aberto',
      },*/
      menuActive: {},
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 50)
  },
  computed: {},
  methods: {
  },
  meta: {
    title: 'Clientes',
    name: 'Clientes'
  }
}
</script>

<template>
  <div class="scontent">
    <div style="height: 0; max-height: 0">
      <menu-clientes v-if="$route.name !== this.routeName" v-show="!loading" ref="menuClientes" />
    </div>
    <router-view />
  </div>
</template>
