<template>
  <div class="smenu">
    <div class="smenu level2" :class="{show: menu === 'default'}">
      <ul class="menu-back">
        <li><a @click="$router.go(-1)"><i class="smenu-icon fas fa-arrow-circle-left fa-fw"></i> Voltar</a></li>
      </ul>
      <ul>
        <page-menu-item label="Resumo" icon="fal fa-list-alt fa-fw" :route="{name: 'clientes.cliente'}"/>
        <page-menu-item disabled label="Serviços" icon="fal fa-briefcase fa-fw" />
        <page-menu-item label="Financeiro" icon="fal fa-credit-card fa-fw" :route="{name: 'clientes.cliente.financeiro'}"/>
      </ul>
      <div class="menu-lbl">Suporte</div>
      <ul>
        <page-menu-item disabled label="Projetos" icon="fal fa-book fa-fw fa-fw" />
        <page-menu-item disabled label="Solicitações" icon="fal fa-list fa-fw fa-fw" />
      </ul>
      <div class="menu-lbl">Infraestrutura</div>
      <ul>
        <page-menu-item label="Instância / Cloud" icon="fal fa-server fa-fw" :route="{name: 'clientes.cliente.instancia'}"/>
        <page-menu-item disabled label="Monitoramento" icon="fal fa-desktop fa-fw fa-fw" />
<!--        <page-menu-item disabled label="Leilões" icon="fal fa-gavel fa-fw fa-fw" />-->
        <page-menu-item disabled label="Backup" icon="fal fa-database fa-fw fa-fw" />
      </ul>
    </div>
  </div>
</template>

<script>
import PageMenuItem from "components/clientes/components/include/PageMenuItem"
import criarClienteWindow from "components/clientes/components/cliente/window/cliente"

export default {
  name: "MenuClientes",
  inject: ['page', 'container'],
  data() {
    return {
      menu: 'default'
    }
  },
  computed: {
    queues() {
      return this.container.queues
    },
    openeds() {
      return this.container.fixedQueues && this.container.fixedQueues.opened
    },
    assignedMes() {
      return this.container.fixedQueues && this.container.fixedQueues.assignedMe
    },
    unassigneds() {
      return this.container.fixedQueues && this.container.fixedQueues.unassigned
    },
    dues() {
      return this.container.fixedQueues && this.container.fixedQueues.due
    },
    closeds() {
      return this.container.fixedQueues && this.container.fixedQueues.closed
    }
  },
  components: {
    PageMenuItem,
  },
  mounted() {
    const node = document.getElementById('cliente-page-menu')
    node.append(this.$el)
  },
  beforeDestroy() {
    const node = document.getElementById('cliente-page-menu')
    node && node.contains(this.$el) && node.removeChild(this.$el)
  },
  methods: {
    criarClienteWindow,
    criarCliente() {
      this.criarClienteWindow(null, null)
    },
    voltar() {
      this.menu = 'default'
    }
  }
}
</script>
